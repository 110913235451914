import { motion } from "framer-motion"
import React from "react"
import { NavLink } from "react-router-dom"
import styled from "styled-components"
import { Github, LinkedIn } from "./AllSvgs"
import { headings } from "./Themes"

const Container = styled.div`
  // padding: 2rem;
  --scroll-behavior: smooth;
  scroll-behavior: smooth;
`

const TopLeftBar = styled.div`
position: absolute;
top: 1rem;
left: 20%;
float: left
width: 100%;
font-family: 'aquaticoregular';
display: flex;
justify-content: flex-end;
.heading {
  font-family: 'aquaticoregular';

}
.heading:hover {
  color: ${(props) => props.theme.text};
  
}
.effect:hover {
  stroke: #ffffff;
}
@media (max-width: 835px){
  left: 10%;
}
@media (max-width: 650px) {
  left: 20px;
}

@media (max-width: 475px){
  left: 0%;
  margin-left: 10px;
  }


`

const Styles = styled.div`
  color: ${(props) => props.theme.text};
  text-decoration: none;
  z-index: 1;
  padding-right: 3rem;
  display: inline;
  font-family: "Fira Mono", monospace;
  .numbers {
    color: ${(props) => props.theme.text};
  }
  @media (max-width: 650px) {
    padding-right: 2rem;
  }

  @media (max-width: 475px) {
    padding-right: 1.5rem;
  }
  @media (max-width: 410px) {
    padding-right: 1rem;
  }

  @media (max-width: 375px) {
    padding-right: 0.7rem;
  }
  @media (max-width: 350px) {
    padding-right: 0.4rem;
  }
  @media (max-width: 330px) {
    padding-right: 1rem;
    margin-left: 1vw;
  }
`

const TopRightBar = styled.div`
position: absolute;
top: 1.3rem;
right: 15%;
font-family: 'aquaticoregular';



.effect:hover {
  stroke: #ffffff;
}

z-index: 10;

}

@media (max-width: 1050px){
right: 10%;
}
@media (max-width: 835px){
right: 0%;
}

@media (max-width: 475px){
  right: 0%;
  margin-right: -15px;
  }
@media (max-width: 410px){
    right: 0%;
    margin-right: -5px;
    }
@media (max-width: 375px){
 right: 0%;
 margin-right: -6px;
 }
 @media (max-width: 350px){
  right: 0%;
  margin-right: 2px;
  }
  @media (max-width: 330px){
    .right{
      display: none;
    }

    }

`

const Work = styled(NavLink)`
  color: ${(props) => props.theme.text};
`
const About = styled(NavLink)`
  color: ${(props) => props.theme.text};
`
const Contact = styled(NavLink)`
  color: ${(props) => props.theme.text};
`
const Webdesign = styled(NavLink)`
  color: ${(props) => props.theme.text};
`
const Header = () => {
  return (
    <Container>
      <TopLeftBar theme={headings}>
        <Styles>
          <Work to="/">
            <motion.h2
              initial={{
                y: -200,
                transition: {
                  type: "spring",
                  duration: 1.5,
                  delay: 0.5,
                },
              }}
              animate={{
                y: 0,
                transition: {
                  type: "spring",
                  duration: 1.5,
                  delay: 0.5,
                },
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
            >
              <h4 class="heading">
                {/* <Numbers theme={headings}>3.</Numbers> */}
                Work
              </h4>
            </motion.h2>
          </Work>
        </Styles>
        <Styles>
          <About to="/About">
            <motion.h2
              initial={{
                y: -200,
                transition: {
                  type: "spring",
                  duration: 1.5,
                  delay: 0.65,
                },
              }}
              animate={{
                y: 0,
                transition: {
                  type: "spring",
                  duration: 1.5,
                  delay: 0.65,
                },
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
            >
              <h4 class="heading">
                {/* <Numbers theme={headings}>1.</Numbers> */}
                About
              </h4>
            </motion.h2>
          </About>
        </Styles>
        {/* <Styles>
          <Webdesign to="/WebDesign">
            <motion.h2
              initial={{
                y: -200,
                transition: {
                  type: "spring",
                  duration: 1.5,
                  delay: 0.75,
                },
              }}
              animate={{
                y: 0,
                transition: {
                  type: "spring",
                  duration: 1.5,
                  delay: 0.75,
                },
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
            >
              <h4 class="heading">
              
                Web Design
              </h4>
            </motion.h2>
          </Webdesign>
        </Styles> */}
        <Styles>
          <Contact to="/Contact">
            <motion.h2
              initial={{
                y: -200,
                transition: {
                  type: "spring",
                  duration: 1.5,
                  delay: 0.85,
                },
              }}
              animate={{
                y: 0,
                transition: {
                  type: "spring",
                  duration: 1.5,
                  delay: 0.85,
                },
              }}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.9 }}
            >
              <h4 class="heading">
                {/* <Numbers theme={headings}>4.</Numbers> */}
                Contact
              </h4>
            </motion.h2>
          </Contact>
        </Styles>
      </TopLeftBar>
      <TopRightBar theme={headings}>
        <Styles>
          <motion.h2
            initial={{
              y: -200,
              transition: {
                type: "spring",
                duration: 1.5,
                delay: 0.95,
              },
            }}
            animate={{
              y: 0,
              transition: {
                type: "spring",
                duration: 1.5,
                delay: 0.95,
              },
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <NavLink
              style={{ color: "inherit" }}
              target="_blank"
              to={{
                pathname: "https://github.com/taylenschmaltz",
              }}
            >
              <Github
                class="effect right"
                width={20}
                height={20}
              />
            </NavLink>
          </motion.h2>
        </Styles>
        <Styles>
          <motion.h2
            initial={{
              y: -200,
              transition: {
                type: "spring",
                duration: 1.5,
                delay: 1.05,
              },
            }}
            animate={{
              y: 0,
              transition: {
                type: "spring",
                duration: 1.5,
                delay: 1.05,
              },
            }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <NavLink
              style={{ color: "inherit" }}
              target="_blank"
              to={{
                pathname:
                  "https://www.linkedin.com/in/taylenschmaltz/",
              }}
            >
              <LinkedIn
                class="effect right"
                width={20}
                height={20}
              />
            </NavLink>
          </motion.h2>
        </Styles>
      </TopRightBar>
    </Container>
  )
}

export default Header
